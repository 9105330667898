
import Axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../config";
import { authHeader } from "../../services/auth";
import Notification from "../../common-components/Notification.vue";
import ProcessorLoanList from "../../common-components/loan-list/ProcessorLoanList.vue";
import ApexChart from "vue-apexcharts";

@Component({
  components: {
    Notification,
    ProcessorLoanList,
    ApexChart,
  },
})
export default class DashBoard extends Vue {
  /* eslint-disable */
  public processorName: any = null;
  public totalLength = 0;
  public borrowerList: any = [];
  public chartOptions = {
    colors: ["#00bc00"],
    chart: {
      type: "radialBar",
      offsetY: -10,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ["Average Results"],
  };

  public async getBorrowerName() {
    this.$store.state.tmpcoLoader = true;
    let res = await Axios.get(
      BASE_API_URL + "borrower/dashboard/getBorrowerName",
      {
        headers: authHeader(),
      }
    );
    this.processorName = res.data.name;
    this.borrowerList = res.data.borrowerList.reverse();
    this.getTotalLoanLength(this.borrowerList);
    this.$store.state.tmpcoLoader = false;
  }

  public async checkLoanDetails(loanId) {
    this.$router.push({
      path: "/borrower-loanDetails",
      query: { id: loanId },
    });
  }

  public getTotalLoanLength(data) {
    this.totalLength = data.length > 0 ? data.length : 0;
  }

  async mounted() {
    await this.getBorrowerName();
  }
  /* eslint-disable */
}
